@media screen and (max-width: 667px) {
    body {
        overflow-x: hidden !important;
    }
    .container {
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
}

.contact {
    background: #121212;
    .container {
        padding: 0px 60px;
        @media (max-width: 991px) {
            padding: 30px 0px;
        }
    }
    .text-right {
        text-align: right;
    }
    @media (min-width: 1400px) {
        .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
            max-width: 1440px !important;
        }
    }

    .social-icons {
        gap: 27px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        i {
            color: #fff;
            font-size: 20px;
        }
        @media (max-width: 991px) { 
            justify-content: flex-start;
            margin-bottom: 0px;
        }
    }

    .masthead-slide {
        background: #121212;
        background-size: cover;
        background-position: center center;
        display: flex;
        align-items: center;
        padding: 160px 0px 70px;
        justify-content: center;
        position: relative;

        @media (max-width: 767px) {
            padding: 150px 30px 50px;
        }

        h1 {
            font-family: 'Cormorant Garamond';
            font-style: normal;
            font-weight: 800;
            text-align: center;
            text-transform: capitalize;
            font-size: 70px;
            line-height: 120%;
            color: #BA9A1C;
            margin-bottom: 8px;
            @media (max-width: 767px) { 
                font-size: 45px;
            }
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 150%;
            color: #fff;
            margin-bottom: 24px;
            @media (max-width: 767px) { 
                font-size: 16px;
            }
        }
    }
    .contact-detail {
        background: #fff;
        padding: 90px 0px;

        @media (max-width: 767px) { 
            padding: 50px 30px 30px;
        }
        p {
            font-weight: 600;
            i {
                margin-right: 10px;
            }
        }
        a {
            text-decoration: none;
            color: #BA9A1C;
        }
        .form {
            margin-bottom: 40px;
            label {
                margin-bottom: 5px;
            }
            .form-control {
                margin-bottom: 20px;
                border-radius: 8px;
            }
            input.form-control {
                height: 50px;
            }
        }
        .btn-launch {
            background: #BA9A1C;
            font-family: 'Inter';
            color: #fff;
            padding: 10px 32px;
            height: 55px;
            border-radius: 16px;
            margin-top: 60px;
            font-weight: 600;
            width: 100%;
        }
    }
}