@media screen and (max-width: 667px) {
    body {
        overflow-x: hidden !important;
    }
    .container {
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
}

.author {
    .container {
        padding: 0px 60px;
        @media (max-width: 991px) {
            padding: 30px 0px;
        }
    }
    .text-right {
        text-align: right;
    }
    @media (min-width: 1400px) {
        .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
            max-width: 1440px !important;
        }
    }

    .masthead-slide {
        background: #1D1B2A;
        background: linear-gradient(to bottom,#0000007e,#000000aa), url(../../assets/author/IMG_8805.jpg);
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        display: flex;
        align-items: center;
        padding: 260px 0px 140px;
        justify-content: center;
        position: relative;
        // min-height: 100vh;

        @media (max-width: 767px) {
            padding: 150px 30px 0px;
        }

        h1 {
            font-family: 'Cormorant Garamond';
            font-style: normal;
            font-weight: 800;
            text-align: center;
            font-size: 70px;
            line-height: 120%;
            color: #BA9A1C;
            margin-bottom: 8px;
            @media (max-width: 767px) { 
                font-size: 35px;
            }
        }
        p {
            font-family: 'Inter';
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 125%;
            color: #fff;
            margin-bottom: 40px;
            @media (max-width: 767px) { 
                margin-bottom: 60px;
                font-size: 16px;
            }
        }
    }
    .author-content {
        background: #f2f2f2;
        padding: 100px 0px;

        @media (max-width: 767px) { 
            padding: 0px 30px 30px;
        }
        
        h1 {
            font-family: 'Cormorant Garamond';
            font-style: normal;
            font-weight: 800;
            text-align: center;
            text-transform: capitalize;
            font-size: 50px;
            line-height: 120%;
            color: #121212;
            margin-bottom: 8px;
            @media (max-width: 500px) { 
                font-size: 35px;
            }
        }

        img {
            margin-bottom: 20px;
            margin-right: 40px;
            border-radius: 8px;
            width: 80%;
            padding: 0px;
            object-fit: cover;
            border: 4px solid #121212;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        }

        h3 {
            font-family: 'Allison Script';
            font-size: 60px;
            text-align: center;
            font-weight: 500;
            color: #BA9A1C;
        }

        h4 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            text-transform: capitalize;
            font-size: 22px;
            line-height: 120%;
            color: #BA9A1C;
            border-radius: 8px;
            margin-bottom: 16px;
            margin-top: 40px;
            i {
                color: #BA9A1C;
            }
            &:nth-child(1) {
                margin-top: 0px;
            }
            @media (max-width: 767px) { 
                font-size: 20px;
                margin-top: 20px;
            }
        }

        p {
            font-family: 'Inter';
            text-align: justify;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 180%;
            color: #121212;
            margin-bottom: 20px;
            @media (max-width: 767px) { 
                margin-bottom: 20px;
                font-size: 16px;
            }
        }
        .faq-item {
            margin-bottom: 34px;
            border-radius: 8px;
            cursor: pointer;
            .faq-content {
                padding: 40px;
                background: #000;
                border-radius: 8px;
                text-align: left;
                @media (max-width: 500px) { 
                    padding: 20px;
                }
                p {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 180%;
                    text-align: left;
                    margin-bottom: 0px;
                    color: #FFFFFF;
                    &.detail {
                        margin-bottom: 25px;
                        // color: #BA9A1C;
                    }
                    &.author {
                        font-weight: 700;
                        color: #BA9A1C;
                    }
                    &.position {
                        // color: #BA9A1C;
                        font-size: 14px;
                        font-style: italic;
                    }
                }
            }
        }
    }
}