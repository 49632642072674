.footer {
    background: #121212;
    padding: 60px 60px 20px;

    @media (max-width: 767px) { 
        padding: 25px 20px;
    }

    .footer-text-p {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: #fff;
        margin: 0;
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 50px;
    }

    img {
        width: 100px;
    }

    p.copyright {
        color: #fff;
        font-size: 12px;
    }

    .address-pane {
        text-align: right;
        @media (max-width: 991px) { 
            margin-top: 20px;
            text-align: left;
        }
        .social-icons {
            gap: 27px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            img {
                width: 28px;
                height: 28px;
            }
            @media (max-width: 991px) { 
                justify-content: flex-start;
                margin-bottom: 0px;
            }
        }
    }

    p, a {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-decoration: none;
        letter-spacing: -0.3px;
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
    a:hover {
        color: #fff;
    }
}