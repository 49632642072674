.navbar {
    background: #fff;
    padding: 5px 40px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 2px 6px 0px;
    .navbar-brand {
        padding: 16px 0px;
        img {
            margin-right: 10px;
            width: 50px;
            @media (max-width: 500px) {
                width: 50px;
            }
        }
        color: #121212;
        font-size: 28px;
        font-weight: 900;
        font-family: Bebas;
        font-family: 'Allison Script';
        color: #BA9A1C;
        letter-spacing: 2px;
        @media (max-width: 767px) {
            font-size: 20px;
        }
    }
    .navbar-nav {
        display: flex;
        align-items: center;
        gap: 30px;
    }
    .nav-link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.3px;
        color: #121212;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin-left: 10px;
        }
        &:hover {
            color: #BA9A1C;
        }
    }
    .nav-link.active, .nav-link.activated {
        color: #BA9A1C;
        &::before {
            content: "";
            bottom: 0px;
            display: block;
            text-align: center;
            position: absolute!important;
            background: #BA9A1C;
            width: 65px;
            height: 4px;
            opacity: 1;
            transition: all .3s ease-in-out;
        }
    }
    .dropdown-menu {
        .dropdown-item {
            padding: 10px 15px !important;
        }
        .dropdown-item.active, .dropdown-item:active {
            color: #121212 !important;
            text-decoration: none !important;
            background-color: #fff !important;
        }
    }
    .ml-auto {
        margin-left: auto;
    }
    .navbar-nav.navbar-center {
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
    }
    button.btn-main {
        padding: 10px 16px;
        font-size: 14px !important;
        font-weight: 600;
        font-family: Inter;
        color: #fff;
        background: #BA9A1C;
        border: 2px solid #BA9A1C;
        border-radius: 16px;
        &:hover {
            background: #fff;
            border: 2px solid #BA9A1C;
            color: #BA9A1C;
        }
        &:focus, &:hover {
            box-shadow: none !important;
        }
        @media (max-width: 767px) {
            padding: 10px;
            font-size: 14px !important;
        }
    }
    .navbar-toggler {
        border-radius: 8px;
        padding: 10px 16px;
        border-color: #121212 !important;
        color: #121212 !important;
        &:hover, &:active, &:focus {
            box-shadow: none !important;
            color: #121212 !important;
            background: #fff;
        }
    }
    @media (max-width: 767px) {
        padding: 5px 10px;
    }
}
.btn {
    &:focus, &:hover {
        box-shadow: none !important;
    }
}