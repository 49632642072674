@media screen and (max-width: 667px) {
    body {
        overflow-x: hidden !important;
    }
    .container {
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
}

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1440px !important;
    }
}

.home4 {
    background: #121212;
    overflow: hidden;
    .container {
        padding: 0px 60px;
        @media (max-width: 991px) {
            padding: 30px 30px;
        }
    }
    .text-right {
        text-align: right;
    }

    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        color: #fff;
    }

    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
        color: #fff;
    }

    .swiper {
        width: 100%;
        height: 100%;
      }
      
      .swiper-slide {
          display: flex;
          align-items: center;
      }
      
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .container-lgs {
        min-height: calc(100vh - 100px) !important;
        display: flex;
        align-items: center;
      }

    .masthead-slide {
        background: #000;
        background: linear-gradient(to bottom,#000000e3,#000000e9), url('../../assets/images/kk2.jpg');
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        min-height: 100vh;
        padding: 140px 0px 70px;
        position: relative;

        @media (max-width: 767px) {
            padding: 130px 0px 50px;
        }

        h3 {
            font-family: 'Cormorant Garamond';
            font-style: normal;
            text-align: left;
            font-weight: 800;
            font-size: 80px;
            line-height: 100%;
            color: #fff;
            margin-bottom: 18px;
            @media (max-width: 767px) { 
                font-size: 50px;
            }
        }
        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            text-align: left;
            font-size: 16px;
            line-height: 150%;
            color: #fff;
            margin-bottom: 24px;
            @media (max-width: 767px) { 
                font-size: 16px;
            }
        }
        p.testimonial-detail {
            font-size: 16px;
            line-height: 180%;
            @media (max-width: 767px) { 
                font-size: 16px;
            }
        }
        p.testimonial-author {
            margin-bottom: 0px;
            text-align: left;
            font-weight: 700;
            line-height: 180%;
            color: #BA9A1C;
        }
        p.testimonial-position {
            font-style: italic;
            line-height: 180%;
        }
        i {
            color: #fff;
            font-size: 30px;
            transform: scale(1, -1);
            &.opposite {
                transform: scale(-1, 1);
            }
        }
        .button-group {
            display: flex;
            gap: 20px;
            margin-bottom: 8px;
            margin-top: 30px;
            flex-wrap: wrap;
        }
        .btn {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.3px;
            padding: 12px 32px;
        }
        .btn-primary {
            border: 2px solid #BA9A1C;
            background: #BA9A1C;
            color: #fff;
            border-radius: 16px;
            transition: all 0.25s ease-in-out;
            box-shadow: 0px 2px 8px rgba(97, 91, 7, 0.15);
            &:hover {
                border: 2px solid #BA9A1C;
                background: #BA9A1C;
                color: #000;
            }
        }
        a {
            text-decoration: none;
        }
        img.img-main {
            border-radius: 16px;
            transform: scale(1.2);
            // @media (max-width: 991px) { 
            //     margin-top: 50px;
            // }
        }
        .author-details {
            img {
                border-radius: 50%;
                border: 2px solid #fff;
                width: 70px;
                height: 70px;
                margin-bottom: 10px;
                margin-right: 15px;
                object-fit: cover;
            }
        }
    }
    .masthead {
        background: #fff;
        background-size: cover;
        background-position: center center;
        display: flex;
        align-items: center;
        padding: 70px 0px 70px;
        position: relative;

        .arrows-img {
            position: absolute;
            top: 50px;
            left: 0px;
            width: 100px;
        }

        @media (max-width: 767px) {
            padding: 50px 10px 50px;
        }

        h1 {
            position: relative;
            font-family: 'Bebas';
            font-family: 'Allison Script';
            font-style: normal;
            font-weight: 800;
            font-size: 150px;
            line-height: 120%;
            // text-transform: capitalize;
            color: #BA9A1C;
            @media (max-width: 767px) { 
                font-size: 60px;
            }
            &::after {
                // content: 'musings';
                position: absolute;
                margin: 0px auto;
                left: 10px;
                right: 0;
                top: 10px;
                font-family: 'Allison Script';
                font-style: normal;
                font-weight: 800;
                font-size: 70px;
                // letter-spacing: 10px;
                text-transform: capitalize;
                color: #121212;
                @media (max-width: 767px) { 
                    font-size: 33px;
                }
            }
        }
        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #121212;
            margin-bottom: 24px;
            @media (max-width: 767px) { 
                font-size: 16px;
            }
        }
        .button-group {
            display: flex;
            gap: 20px;
            margin-bottom: 8px;
            flex-wrap: wrap;
        }
        .btn {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.3px;
            padding: 12px 32px;
        }
        .btn-primary {
            border: 2px solid #BA9A1C;
            background: #BA9A1C;
            color: #fff;
            border-radius: 16px;
            transition: all 0.25s ease-in-out;
            &:hover {
                border: 2px solid #BA9A1C;
                background: transparent;
                color: #BA9A1C;
            }
        }
        .btn-secondary {
            border: none;
            color: #121212;
            padding: 16px 0px;
            display: flex;
            align-items: center;
            background: #fff;
            opacity: .8;
            border-radius: 16px;
            i {
                margin-left: 10px;
                font-weight: 900;
                transition: all 0.25s ease-in-out;
            }
            &:hover {
                color: #121212;
                opacity: 1;
                i {
                    margin-left: 15px;
                }
            }
            @media (max-width: 423px) {
                font-size: 14px;
            }
        }
        a {
            text-decoration: none;
        }
        img.img-main {
            @media (max-width: 991px) { 
                margin-top: 50px;
            }
        }
    }
    .author-section {
        background: #000;
        background: linear-gradient(to bottom,#000000ce,#000000d2), url('https://bolaakindele.com/wp-content/uploads/revslider/corporate/slidersmooth.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        position: relative;
        display: flex;
        align-items: center;
        padding: 100px 0px;
        @media (max-width: 767px) {
            padding: 50px 10px 30px;
        }
        .arrows-img {
            position: absolute;
            top: 250px;
            right: 40px;
            width: 50px;
        }
        h1 {
            font-family: 'Bebas';
            font-family: 'Allison Script';
            font-style: normal;
            font-weight: 800;
            // text-transform: capitalize;
            font-size: 80px;
            line-height: 120%;
            color: #BA9A1C;
            position: relative;
            margin-bottom: 16px;
            @media (max-width: 767px) { 
                font-size: 50px;
            }
        }
        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #fff;
            margin-bottom: 16px;
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
        a {
            color: #BA9A1C;
        }
        span {
            font-weight: 400;
            color: #fff;
        }
        .author-img {
            border: 4px solid #fff;
            border-radius: 8px;
        }

        .social-icons {
            gap: 27px;
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            i {
                color: #fff;
                font-size: 20px;
            }
            @media (max-width: 991px) { 
                justify-content: flex-start;
                margin-bottom: 20px;
            }
        }
    }
    .reviews {
        background: #fff;
        padding: 100px 0px;

        @media (max-width: 767px) { 
            padding: 50px 0px 50px;
        }
        
        h1 {
            font-family: 'Cormorant Garamond';
            font-style: normal;
            font-weight: 800;
            text-align: center;
            text-transform: capitalize;
            font-size: 50px;
            line-height: 120%;
            color: #1D1B2A;
            margin-bottom: 40px;
            @media (max-width: 767px) { 
                font-size: 40px;
            }
        }
        p {
            font-family: 'Inter';
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 125%;
            color: #121212;
            margin-bottom: 40px;
            @media (max-width: 767px) { 
                margin-bottom: 40px;
                font-size: 16px;
            }
        }
        .review-item-content {
            cursor: pointer;
            border: 1px solid #1d1b2a2f;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 11px 20px 0px;
            margin-bottom: 34px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 30px 40px 40px;
            background: rgb(14, 66, 145);
            background: #fff;
            border-radius: 8px;
            text-align: left;
            @media (max-width: 500px) { 
                padding: 20px;
            }
            .quote {
                font-size: 60px;
                color: #BA9A1C;
                opacity: .8;
                margin-bottom: 10px;
            }
            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 180%;
                text-align: left;
                margin-bottom: 0px;
                color: #121212;
                &.detail {
                    margin-bottom: 25px;
                }
                &.author {
                    font-weight: 700;
                    color: #121212;
                    margin-bottom: 0px;
                    line-height: 150%;
                }
                &.position {
                    font-size: 13px;
                    font-style: italic;
                    line-height: 150%;
                    opacity: 0.8;
                }
            }
            img {
                border-radius: 50%;
                border: 2px solid #BA9A1C;
                width: 70px;
                height: 70px;
                margin-right: 15px;
                object-fit: cover;
            }
        }
        .btn {
            padding: 10px 32px;
            border-radius: 8px;
            background: #1D1B2A;
            border-color: #1D1B2A;
        }
    }
    .book-launch {
        background: #BA9A1C;
        padding: 100px 0px 100px;
        @media (max-width: 767px) {
            padding: 0px 30px;
        }
        img {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
        }
        .content {
            background: #121212;            
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            margin-top: 70px;
            margin-bottom: 50px;
            border-radius: 8px;
            padding: 60px 60px;
            h1 {
                color: #fff;
                font-size: 50px;
                margin-bottom: 16px;
                font-family: 'Cormorant Garamond';
                font-style: normal;
                font-weight: 800;
                line-height: 120%;
                span {
                    position: relative;
                    color: #BA9A1C;
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0px;
                        bottom: 4px;
                        width: 100%;
                        height: 14px;
                        background: #4698eb;
                        z-index: -1;
                    }
                }
                @media (max-width: 500px) { 
                    font-size: 35px;
                    margin-bottom: 8px;
                }
            }
            p {
                color: #fff;
                font-weight: 600;
                margin-bottom: 24px;
                line-height: 150%;
                opacity: .9 !important;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                @media (max-width: 500px) { 
                    margin-bottom: 60px;
                    font-size: 16px;
                }
            }
            .btn-primary {
                background: #BA9A1C;
                border: 2px solid #BA9A1C;
                font-family: 'Inter';
                color: #121212;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.3px;
                padding: 16px 32px;
                &:hover {
                    background: #fff;
                    border: 2px solid #fff;
                    color: #121212;
                }
                @media (max-width: 500px) {
                    padding: 16px;
                }
            }
            @media (max-width: 991px) {
                .text-right {
                    text-align: left;
                }
            }
            @media (max-width: 767px) {
                padding: 60px 60px;
            }
            @media (max-width: 500px) {
                padding: 60px 30px;
            }
        }
    }
}