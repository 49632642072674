@media screen and (max-width: 667px) {
    body {
        overflow-x: hidden !important;
    }
    .container {
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
  }

.reviews {
    background: #121212;
    .container {
        padding: 0px 60px;
        @media (max-width: 991px) {
            padding: 30px 0px;
        }
    }
    .text-right {
        text-align: right;
    }
    @media (min-width: 1400px) {
        .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
            max-width: 1440px !important;
        }
    }

    .social-icons {
        gap: 27px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        i {
            color: #fff;
            font-size: 20px;
        }
        @media (max-width: 991px) { 
            justify-content: flex-start;
            margin-bottom: 0px;
        }
    }

    .masthead-slide {
        background: #121212;
        // background: linear-gradient(to bottom,#000000c5,#000000d6), url('../../assets/images/paperback.svg');
        background-size: contain;

        background-position: center center;
        background-attachment: fixed;
        display: flex;
        align-items: center;
        padding: 160px 0px 40px;
        justify-content: center;
        position: relative;

        @media (max-width: 767px) {
            padding: 150px 30px 20px;
        }

        h1 {
            font-family: 'Cormorant Garamond';
            font-style: normal;
            font-weight: 800;
            text-align: center;
            font-size: 70px;
            line-height: 120%;
            color: #BA9A1C;
            margin-bottom: 40px;
            @media (max-width: 767px) { 
                font-size: 45px;
            }
        }
        p {
            font-family: 'Inter';
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 125%;
            color: #fff;
            margin-bottom: 40px;
            @media (max-width: 767px) { 
                margin-bottom: 60px;
                font-size: 16px;
            }
        }
    }
    .reviews-content {
        background: #fff;
        padding: 100px 0px;

        @media (max-width: 767px) { 
            padding: 0px 30px 30px;
        }
        
        h1 {
            font-family: 'Cormorant Garamond';
            font-style: normal;
            font-weight: 800;
            text-align: center;
            text-transform: capitalize;
            font-size: 50px;
            line-height: 120%;
            color: #121212;
            margin-bottom: 8px;
            @media (max-width: 500px) { 
                font-size: 35px;
            }
        }
        p {
            font-family: 'Inter';
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 125%;
            color: #121212;
            margin-bottom: 40px;
            @media (max-width: 500px) { 
                margin-bottom: 60px;
                font-size: 16px;
            }
        }
        .review-item-content {
            cursor: pointer;
            border: 1px solid #1d1b2a2f;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 11px 20px 0px;
            margin-bottom: 34px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 30px 40px 40px;
            background: #fff;
            border-radius: 8px;
            text-align: left;
            @media (max-width: 500px) { 
                padding: 20px;
            }
            .quote {
                font-size: 60px;
                color: #BA9A1C;
                opacity: .8;
                margin-bottom: 10px;
            }
            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 180%;
                text-align: left;
                margin-bottom: 0px;
                color: #121212;
                &.detail {
                    margin-bottom: 25px;
                }
                &.author {
                    font-weight: 700;
                    color: #121212;
                    margin-bottom: 0px;
                }
                &.position {
                    font-size: 14px;
                    font-style: italic;
                    opacity: 0.8;
                }
            }
            img {
                border-radius: 50%;
                border: 2px solid #BA9A1C;
                width: 70px;
                height: 70px;
                margin-right: 15px;
                object-fit: cover;
            }

            .img-zoom {
                object-fit: cover;
            }
        }
    }
}