@media screen and (max-width: 667px) {
    body {
        overflow-x: hidden !important;
    }
    .container {
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
}

.book {
    .container {
        padding: 0px 60px;
        @media (max-width: 991px) {
            padding: 30px 0px;
        }
    }
    @media (min-width: 1400px) {
        .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
            max-width: 1440px !important;
        }
    }

    .content {
        background: #000;
        padding: 140px 50px 30px;
        @media (max-width: 991px) {
            padding: 70px 10px;
        }
        .chapter {
            background: #fff;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
            margin-bottom: 60px;
            padding: 40px 60px;
            border: 2px solid #f2f2f2;
            border-radius: 8px;
            @media (max-width: 991px) {
                padding: 30px 20px;
            }
        }
        h1 {
            text-align: center;
            font-family: Allison Script;
            margin-bottom: 0px;
            font-weight: 700;
            color: #BA9A1C;
        }
        p.chapter-title {
            font-family: Bebas;
            letter-spacing: 2px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 30px;
            margin-bottom: 25px;
        }
        h4 {
            font-size: 20px;
            font-weight: 700;
            margin: 30px 0px 20px;
        }
        h5 {
            font-size: 18px;
            font-weight: 700;
            opacity: .8;
        }
        p {
            text-align: justify;
            line-height: 180%;
            font-size: 16px;
        }
        i {
            display: block;
            margin-bottom: 20px;
        }
        ol, ul {
            li {
                line-height: 180%;
            }
        }
        .img-section {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;
            p {
                font-style: italic;
                text-align: center;
            }
        }
        .bw {
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
        }
    }

    .order-section {
        background: #fff;
        padding: 100px 0px;
        min-height: 300px;

        @media (max-width: 767px) { 
            padding: 0px 30px 30px;
        }
        
        h1 {
            font-family: 'Cormorant Garamond';
            font-style: normal;
            font-weight: 800;
            text-align: left;
            font-size: 50px;
            line-height: 120%;
            color: #121212;
            margin-bottom: 32px;
            @media (max-width: 767px) { 
                font-size: 35px;
            }
        }

        h4 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            text-transform: capitalize;
            font-size: 22px;
            line-height: 120%;
            color: #1D1B2A;
            // background: #1D1B2A;
            // color: #fff;
            // padding: 10px 20px;
            border-radius: 8px;
            margin-bottom: 16px;
            margin-top: 40px;
            @media (max-width: 767px) { 
                font-size: 35px;
            }
        }

        p {
            font-family: 'Inter';
            text-align: left;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: #121212;
            margin-bottom: 20px;
            @media (max-width: 767px) { 
                margin-bottom: 60px;
                font-size: 16px;
            }
        }
        .btn {
            padding: 10px 32px;
            font-family: 'Inter';
            border-radius: 8px;
            background: #BA9A1C;
            border-color: #BA9A1C;
            font-weight: 600;
            border-radius: 16px;
        }
    }
}