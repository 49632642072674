@media screen and (max-width: 667px) {
    body {
        overflow-x: hidden !important;
    }
    .container {
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
}

.order {
    background: #121212;
    .container {
        padding: 0px 60px;
        @media (max-width: 991px) {
            padding: 30px 0px;
        }
    }
    .text-right {
        text-align: right;
    }
    @media (min-width: 1400px) {
        .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
            max-width: 1440px !important;
        }
    }

    .social-icons {
        gap: 27px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        i {
            color: #fff;
            font-size: 20px;
        }
        @media (max-width: 991px) { 
            justify-content: flex-start;
            margin-bottom: 0px;
        }
    }

    .masthead-slide {
        background: #121212;
        background-size: cover;
        background-position: center center;
        display: flex;
        align-items: center;
        padding: 130px 0px 40px;
        justify-content: center;
        position: relative;

        @media (max-width: 767px) {
            padding: 150px 30px 50px;
        }

        h1 {
            font-family: 'Cormorant Garamond';
            font-style: normal;
            font-weight: 800;
            text-align: center;
            font-size: 50px;
            line-height: 120%;
            color: #BA9A1C;
            margin-bottom: 8px;
            @media (max-width: 767px) { 
                font-size: 35px;
            }
        }

        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 150%;
            color: #fff;
            margin-bottom: 24px;
            @media (max-width: 767px) { 
                font-size: 16px;
            }
        }
    }
    .order-content {
        background: #fff;
        padding: 70px 0px;

        @media (max-width: 767px) { 
            padding: 0px 30px 30px;
        }

        .books {
            text-align: center;
            border-bottom: 2px solid #f2f2f2;
            padding-bottom: 70px;
            p {
                text-align: center;
                font-weight: 600;
            }
            .btn {
                text-align: left;
                margin-bottom: 15px;
                padding: 15px 32px;
                color: #BA9A1C;
                font-weight: 600;
                background: #fff;
                border: 2px solid #BA9A1C;
                border-radius: 8px;
                &:hover {
                    background: #BA9A1C;
                    color: #fff;
                }
            }
        }
        
        .platforms {
            margin-top: 50px;
            h4 {
                margin-bottom: 20px;
                text-align: center;
            }
            p {
                margin-bottom: 5px;
                font-weight: 600;
            }
            .btn {
                width: 100%;
                margin-bottom: 15px;
                padding: 15px 32px;
                background: #f2f2f2;
                border-color: #d5d5d5;
                border-radius: 8px;
                &.sellar {
                    background: #5A0B4D;
                    border-color: #5A0B4D;
                    padding: 0px 32px;
                    img {
                        width: 60px;
                    }
                }
                &.okadabooks {
                    background: #FEE011;
                    border-color: #FEE011;
                    padding: 0px 32px;
                    img {
                        width: 120px;
                    }
                }
                &.scribd {
                    background: #fff;
                }
                img {
                    width: 150px;
                }
            }
        }
    }
}