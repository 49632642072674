body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Allison Script';
  src: url('./app/assets/fonts/allison_script-webfont.woff2') format('woff2'),
       url('./app/assets/fonts/allison_script-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/* @font-face {
  font-family: 'Bebas';
  src: url('./app/assets/fonts/bebas-regular-webfont.woff2') format('woff2'),
       url('./app/assets/fonts/bebas-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: 'Bebas';
  src: url('./app/assets/fonts/bebas-regular-webfont.woff2') format('woff2'),
       url('./app/assets/fonts/bebas-regular-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
} */

@font-face {
  font-family: 'Bebas';
  src: url('./app/assets/fonts/BebasRegular.eot');
  src: url('./app/assets/fonts/BebasRegular.eot') format('embedded-opentype'),
       url('./app/assets/fonts/BebasRegular.woff2') format('woff2'),
       url('./app/assets/fonts/BebasRegular.woff') format('woff'),
       url('./app/assets/fonts/BebasRegular.ttf') format('truetype'),
       url('./app/assets/fonts/BebasRegular.svg#BebasRegular') format('svg');
}